<template>
  <div class="text-center pa-4" style="background: #f3f3f9 none repeat scroll 0 0; padding: 25px 50px 50px 50px !important;">
    <v-btn  class="filterButton text-right" style="
      position: fixed;
      top: 15px;
      z-index: 20;
      right: 15%;color: white;" 
      depressed @click="openModal()"
     
    >
      <v-icon>mdi-plus</v-icon>Add company
    </v-btn>
    <div class="headingprofile">
      <v-row class="w-100 ma-0">
        <v-col cols="12" class="pa-0">
          <v-row class="w-100 ma-0 " style="background-color: white;border-radius:6px;">
            <v-col @click="changeTab('')" style="display:flex;cursor: pointer;max-width: 120px ;" class="pa-0 ">
              <span :class="$route.name === 'dashboard' ? 'activeTab' : ''" style="padding:10px;display:flex;border-radius: 8px;">
                <span class="mdi mdi-view-dashboard-outline" style="padding-right:2px"></span>Dashboard
              </span>
            </v-col>
            <v-col @click="changeTab('company-list')" style="display: flex;cursor: pointer;border-radius:6px;" class="pa-0 ">
              <span :class="$route.name !== 'dashboard' ? 'activeTab' : ' hover'" style="white-space: nowrap;padding:10px;display:flex;border-radius: 8px;text-align:center;align-items: center;justify-content: center;"><span class="mdi mdi-format-list-bulleted" style="padding-right:2px"></span>
                Company list 
              </span>
            </v-col>
            <v-col  style="display:flex;cursor: pointer;     justify-content: flex-end;" cols="9" class="pa-0">
              <v-btn @click="toggleDrawer" class="filterButton" style="height: calc(100% - 10px);margin: 5px;" >
                <v-badge
    v-if="filter_type || filter_status"
    :content="badgeCount"
    color="#0ab39c" 
    overlap
    style="bottom: 20px;left:90px;"
  >
  </v-badge><span class="mdi mdi-filter-outline" style="font-size: 20px;"></span>
  Filters
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="w-100 ma-0 mt-4 mb-2" style="align-items: center;">
        <v-col cols="10" class="pl-0">
          <h2 style="font-weight:300; font-size:30px;">Hi, Welcome back</h2>
          <p style="font-weight:300; font-size:16px;" class="mb-0">Amara's back-end administration panel.</p>
        </v-col>
        <!-- <v-col  class="text-right" style="display:flex;padding-right: 0; justify-content: flex-end;">
          <div>
          <p style="color: #878888;margin:0;">Total companies</p>
          <h3 style="color: #4d575d; font-size: 25px;margin: 5px 0 0;font-weight: 300;" v-if="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.companies_count">{{dashboardData.dashboard_overview.companies_count}}</h3>
        </div><div>
          <v-icon style="font-size:60px; color:#ff6b6b;"> mdi-office-building</v-icon>
        </div>
        </v-col>
        <v-col  class="text-right" style="margin-left:10px;display:flex;padding-right: 0; justify-content: flex-end;">
          <div>
          <p style="color: #878888;margin:0;" >Total users</p>
          <h3 style="color: #4d575d; font-size: 25px;margin: 5px 0 0;font-weight: 300;" v-if="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.users_count">{{dashboardData.dashboard_overview.users_count}}</h3>
        </div><div>
          <v-icon style="font-size:60px; color:#6e6eff;padding-left:10px;">mdi-account-group</v-icon>
        </div>
        </v-col> -->
        <v-col cols="2"  class="" style="padding-right: 0;display: flex; background: white; border-radius:10px; text-align:left;">
          <div style="display:inline-block;vertical-align: top; width:60px; height:60px;background: #ff6b6b; border-radius:100px; padding: 16px;">
            <v-icon style=" font-size:30px; color: white;">mdi mdi-currency-inr</v-icon>
          </div>
          <div style="display:inline-block; padding: 0 0 0 15px;" >
            <p style="color: #878888;margin:0;" title="Total annual recurring revenue">Total ARR</p>
            <h3 style="color: #4d575d; display:inline-block; font-size: 25px;    vertical-align: top;;margin: 0px 0 0;font-weight: 300;" v-if="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.companies_total_arr">{{dashboardData.dashboard_overview.companies_total_arr}}
            </h3>
            <h3 style="color: #4d575d; display:inline-block;width: 90px; font-size: 25px;    vertical-align: top;;margin: 0px 0 0;font-weight: 300;" v-else>--
            </h3>
          </div>
        </v-col>
        <!-- <v-col  class="text-right" style="padding-right: 0;display: flex;justify-content: flex-end;">
          <div >
          <p style="color: #878888;margin:0;">Users this Month</p>
          <h3 style="color: #4d575d; font-size: 25px;margin: 5px 0 0;font-weight: 300;" v-if="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.user_this_month">      {{ dashboardData.dashboard_overview.user_this_month }}          </h3>
        </div>
        <div style="display: inline-block; vertical-align: top; width: 60px; height: 60px; background: #63d6ff; border-radius: 100px; padding: 16px;">
          <v-icon style="font-size: 30px; color: white;">mdi-account-group</v-icon>
        </div>
        </v-col> -->
      </v-row>
    </div>
    <div>
    <v-row style="width:100%; margin:0;">
      <v-col cols="6" class="px-0 pb-0 ">
        <v-row style="width:100%; margin:0;">
          <v-col cols="6" class="pl-0 pt-0">
            <div style="background: white; height: 120px; border-radius:10px; padding: 25px 0 25px 15px;text-align:left;">
              <div style="display:inline-block;vertical-align: top; width:60px; height:60px;background: #ff6b6b; border-radius:100px; padding: 16px;">
                <v-icon style=" font-size:30px; color: white;">mdi-office-building</v-icon>
              </div>
              <div style="display:inline-block; padding: 0 0 0 15px;" v-if="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.companies_count">
                <p style="color: #878888;margin:0;">Total companies</p>
                <h3 style="color: #4d575d; line-height:48px; display:inline-block;width: 90px; font-size: 25px;    vertical-align: top;;margin: 0px 0 0;font-weight: 300;" >{{dashboardData.dashboard_overview.companies_count}}
                </h3>
                <div style="display:inline-block; border-left: 1px solid #878888; " v-if="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.companies_status">
                  <small style="margin-left: 10px;" v-for="(u, i) in dashboardData.dashboard_overview.companies_status" :key="i">
                  <span class="text-capitalize" v-if="u.is_active">Active </span>
                  <span class="text-capitalize" v-else>Inactive </span>:<span> {{u.count}}</span><br>
                  </small>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" class="pl-0 pt-0">
            <div style="background: white; height: 120px; border-radius: 10px; padding: 25px 0 25px 15px; text-align: left;">
 
  <div style="display:flex;">
    <div style="display: inline-block; vertical-align: top; width: 60px; height: 60px; background: #63d6ff; border-radius: 100px; padding: 16px;">
    <v-icon style="font-size: 30px; color: white;">mdi-account-group</v-icon>
  </div>
  <div style="display: inline-block; padding: 0 0 0 15px;vertical-align: top;">
    <p style="color: #878888; margin: 0; width: 90px; ">Total users</p>
    <h3 style="color: #4d575d; line-height:48px; display:inline-block;width: 90px; font-size: 25px;    vertical-align: top;;margin: 0px 0 0;font-weight: 300;" v-if="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.users_count">
      {{ dashboardData.dashboard_overview.users_count }}
    </h3>
    <h3 style="color: #4d575d; line-height: 30px; display: inline-block; width: 90px; font-size: 20px; vertical-align: top; margin: 0px 0 0; font-weight: 300;" v-else>
      --
    </h3>
  </div>
    <div style="border-left: 1px solid #878888;" v-if="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.users_status">
      <small style="margin-left: 10px;" v-for="(u, i) in dashboardData.dashboard_overview.users_status" :key="i">
        <span class="text-capitalize">{{ u.status }} </span>:<span> {{ u.count }}</span><br>
      </small>
    </div>
  </div>
</div>

          </v-col>
          <v-col cols="6" class="pl-0">
    <div style="background: white; height: 120px; border-radius:10px; padding: 25px 15px;text-align:left;">
      <div style="display: flex; align-items: center;  gap: 15px;">
    <div style="width: 60px; height: 60px; background: #6e6eff; border-radius: 100px; padding: 16px; display: flex; align-items: center; justify-content: center;">
        <v-icon style="font-size: 30px; color: white;">mdi-currency-usd</v-icon>
    </div>
    <!-- eslint-disable -->
    <div style="display: flex; align-items: center; gap: 15px; color: #878888;" v-if="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.account_type">
      <div style="text-align: left;" v-for="(ac, t) in dashboardData.dashboard_overview.account_type" :key="t" v-if="ac.account_type == 'paid'">
            <p style="color: #878888;margin:0;">Total paid</p>
            <p style="color: #4d575d;  display:inline-block;width: 90px; font-size: 25px;    vertical-align: top;;margin: 0px 0 0;font-weight: 300;">{{ac.count}}</p>
        </div>
        <div style="border-left: 1px solid #878888; height: 60px;"></div>
        <div style="text-align: left;" v-for="(ac1, t2) in dashboardData.dashboard_overview.account_type" :key="t2" v-if="ac1.account_type == 'pilot'">
            <p style="color: #878888;margin:0;">Total pilot</p>
            <p style="color: #4d575d;  display:inline-block;width: 90px; font-size: 25px;    vertical-align: top;;margin: 0px 0 0;font-weight: 300;">{{ac1.count}}</p>
        </div>
    </div>   
</div>
    </div>
</v-col>

<v-col cols="6" class="pl-0">
  <div style="background: white; height: 120px; border-radius:10px; padding: 25px 0 25px 15px;text-align:left;">
    <div style="display:inline-block;vertical-align: top; width:60px; height:60px;background: #ff6b6b; border-radius:100px; padding: 16px;">
      <v-icon style=" font-size:30px; color: white;">mdi mdi-account-clock</v-icon>
    </div>
    <div style="display:inline-block; padding: 0 0 0 15px;" >
      <p style="color: #878888;margin:0;">Users this month</p>
      <h3 style="color: #4d575d; font-size: 25px;font-weight: 300;" v-if="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.user_this_month">{{ dashboardData.dashboard_overview.user_this_month }}          </h3>
      <h3 style="color: #4d575d; line-height:48px; display:inline-block;width: 90px; font-size: 25px;    vertical-align: top;;margin: 0px 0 0;font-weight: 300;" v-else>--
      </h3>
    </div>
  </div>
</v-col>

          <v-col cols="12" class="pl-0 text-left">
            <v-row style="width:100%; min-height:282px; align-items: center; margin:0;background: white; border-radius:10px; padding: 25px 30px;text-align:left;">
              <v-col cols="4">
                <div v-show="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.chat_response_stats.this_month" >
                  <div id="chartdiv"></div>
                </div>
                <div v-show="dashboardData && dashboardData.dashboard_overview && !dashboardData.dashboard_overview.chat_response_stats.this_month" >
                  <div style="border: 3px solid #6794dc;width: 170px;height: 170px;border-radius: 100%;padding: 60px 13px;text-align: center;">No chats this month</div>
                </div>
              </v-col>
              <v-col cols="4" style="    padding: 31px;" v-if="dashboardData && dashboardData.dashboard_overview && dashboardData.dashboard_overview.chat_response_stats">
                <p class="mb-0 heading">Total chats</p>
                <h3 style="display:inline-block; width:200px;color: rgb(77, 87, 93); font-size: 25px; margin: 5px 0px 0px; font-weight: 300;">{{dashboardData.dashboard_overview.chat_response_stats.total}}</h3>
              </v-col>
              <v-col style="border-left: 1px solid #878888; padding:0; padding-left:66px; " v-if="dashboardData" cols="4">
                <v-row class="" style="display:inline-block;">
                  <v-col cols="12" v-for="(stats, s) in dashboardData.dashboard_overview.chat_response_stats.stats" :key="s" class="pt-0">
                    <h3  style="color: rgb(77, 87, 93); font-size: 25px; margin: 5px 0px 0px; font-weight: 300;">{{stats.initiated}}</h3>
                    <span v-if="stats.initiated">Initiated</span>
                    <h3 style="color: rgb(77, 87, 93); font-size: 25px; margin: 5px 0px 0px; font-weight: 300;">{{stats.inProgress}}</h3>
                    <span v-if="stats.inProgress">Inprogress</span>
                    <h3 style="color: rgb(77, 87, 93); font-size: 25px; margin: 5px 0px 0px; font-weight: 300;">{{stats.completed}}</h3>
                    <span v-if="stats.completed">Completed</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="pb-0 pr-0">
        <div style="margin:0; height: 576px; overflow-y: auto; background: white; border-radius:10px; padding: 0px 0px;text-align:left;" >
          <v-row class="ma-0 w-100">
            <v-col cols="6">
              <p style="color: #878888;margin:0;">Activities</p>
            </v-col>
            <v-col cols="6">
              <div style="float:right" class="right d-flex align-center" >
                <div class="pre-icon cursor-pointer mr-3 pt-1" @click="displayPre()">
                  <v-icon>mdi-arrow-left-drop-circle-outline</v-icon>
                </div>
                <div>{{getPreNumber}} - {{getNextNumber}} of {{totalCount}}</div>
                <div class="next-icon cursor-pointer ml-3 pt-1" @click="displayNext()">
                  <v-icon>mdi-arrow-right-drop-circle-outline</v-icon>
                </div>
              </div>
            </v-col>
          </v-row>
          <div style="height: 500px; overflow-y: auto;" class="infinite-wrapper">
            <v-timeline v-if="auditLog" style="padding:20px 20px 0 0px;"
              align-top
              dense
            >
              <v-timeline-item
                v-for="(audit, i) in auditLog"
                :key="i"
                :color="getColor(audit.type)"
                :icon="getIcon(audit.type)"
                fill-dot
              >
                <v-card
                  :color="getColor(audit.type)"
                  dark
                >
                  <v-card-title style="font-size: 14px;padding: 0 10px; color: #263238;">
                    <v-row>
                      <v-col cols="9">
                        {{audit.type[0].toUpperCase() + audit.type.slice(1).split('_').join(' ')}}
                      </v-col>
                      <v-col cols="3">
                        <p class="mb-0"
                        @click="viewLogDetails(audit)" style="font-size: 80%;text-decoration: underline;text-align: right;cursor:pointer;">View details</p>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text class="white pa-2 text-left text--primary">
                    <v-row>
                      <v-col cols="4">
                        {{ moment(audit.created_at).format('DD MMM YYYY hh:mm  a') }}
                      </v-col>
                      <v-col cols="8">
                        <p v-if="audit.type === 'user_login_failed'" class="mb-0"><span v-if="audit.username">{{audit.username}}</span>'s login failed.</p>
                        <p v-if="audit.type === 'user_login_failed'" class="mb-0">Error: {{audit.error}}</p>
                        <p v-if="audit.type === 'user_logged_in'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has logged in successfully.</p>
                        <p v-if="audit.type === 'user_logged_out'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has logged out successfully.</p>
                        <p v-if="audit.type === 'report_download'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has downloaded the <strong><span v-if="audit.data && audit.data.report_name">{{audit.data.report_name}}</span></strong> of <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'admin_added'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has added admin in <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'plan_settings'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has changed plan details for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'sub_driver_updated'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has updated sub driver <strong v-if="audit && audit.data">{{audit.data}}</strong> for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'sub_driver_added'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has added a new sub driver <strong v-if="audit && audit.data">{{audit.data}}</strong> for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'sub_driver_deleted'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has deleted a sub driver <strong v-if="audit && audit.data">{{audit.data}}</strong> for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'company_settings'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has changed company settings for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'company_profile'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has changed company profile for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'company_added'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has added a new company <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'touchpoint_index'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has change touchpoint index for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'touchpoint_upload'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has uploaded the touchpoint for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'touchpoint_deleted'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has deleted a touchpoint <strong v-if="audit && audit.data">{{audit.data}}</strong> for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'touchpoint_updated'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has updated a touchpoint <strong v-if="audit && audit.data">{{audit.data}}</strong> for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'template_updated'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has updated a template <strong v-if="audit && audit.data">{{audit.data.replace(/_/g, ' ')}}</strong> for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                        <p v-if="audit.type === 'reminders_trigger'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has trigger reminders for <strong><span style="cursor:pointer;" @click="openCompany(audit.company.id)" v-if="audit.company && audit.company.company_name">{{audit.company.company_name}}</span></strong>.</p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
            <infinite-loading @infinite="infiniteHandler" spinner="waveDots">
              <span slot='no-more'>{{' '}}</span>
              <span slot='no-results'>{{' '}}</span>
            </infinite-loading>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div class="firstNew" style="height: 400px; width:100%;background: white;border-radius: 10px; ">
          <p class="pa-4 text-left" style="color: rgb(135, 136, 136); margin: 0px;">Avatars</p>
          <v-row class="ma-0 w-100" style="max-height:335px; padding:0 20px; overflow-y: auto;">
            <v-col cols="3" v-for="(a, i) in avatarList" :key="i">
              <div class="avatarBox" style="max-height:135px; max-width:135px; border:none;">
                <v-avatar
                  size="140"
                  color="grey lighten-4"
                >
                  <div class="file-upload-form">
                    <input v-if="!a.avatar" type="file" @change="previewImage" accept="image/*">
                  </div>
                  <div class="image-preview" v-if="a && a.avatar" style="position:relative;">
                      <img style="margin-top: 5px;margin-inline:5px;padding: 2px;border-radius:100%;width:100%;" class="preview" :src="a.avatar" alt="image">
                      <div v-if="!a.is_active" class="overlay">Disabled</div>
                      <div @click="disableImage(a.id)" v-if="a.is_active" class="overlay1">Click here to disable</div>
                      <div @click="enableImage(a.id)" v-if="!a.is_active" class="overlay2">Click here to enable</div>
                  </div>
                </v-avatar>
              </div>
            </v-col>
            <v-col cols="3" >
              <div class="avatarBox" style="border:none;">
                <v-avatar  @click="addNewAvatar()" style="cursor:pointer;"
                  size="140"
                  color="grey lighten-4"
                >
                  <div style="padding: 5%;" icon>
                    <v-icon style=" font-size:30px; color: black;">mdi-account-plus</v-icon><br>
                    Add new
                  </div>
                </v-avatar>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="6">
        <div style="height: 400px; width:100%;background: white; border-radius: 10px;">
          <p class="pa-4 text-left" style="color: rgb(135, 136, 136); margin: 0px;">Month wise company added</p>
          <div id="chartdiv1"></div>
        </div>
      </v-col>
    </v-row>
  </div>
  <v-navigation-drawer
      v-model="drawerOpen" class="filterDrawer"
      app
      right
      temporary
      width="400"
    >
  <v-list style="margin-top: 70px;">
    <v-list-item style="align-items:left">
  <v-list-item-content>
    <v-list-item-title style="font-size:20px;font-weight:600;text-align: left;">
      Company Filters
    </v-list-item-title>
    <v-list-item-title style="text-align: left;">
      Filters will be applied to Dashboard
    </v-list-item-title>
  </v-list-item-content>
  <v-btn @click="resetFilters" style="border:1px solid #0ab39c;color:#0ab39c;background-color: white;box-shadow: none;text-transform: capitalize;"      v-if="filter_type || filter_status"   >
    <span class="mdi mdi-restore"></span>
    Reset
  </v-btn>
</v-list-item>
    <v-expansion-panels bg-color="white" flat style="border-bottom:1px solid #0003;border-top:1px solid #0003;border-radius:0px !important;margin-left: 1px;">
      <v-expansion-panel  style="padding-top:10px;padding-bottom:10px;margin-top:5px;">
        <v-expansion-panel-header>
          Company Type
          <v-chip 
        v-if="filter_type" 
        style="float: right; background-color: white;border:1px solid #0ab39c;color:#0ab39c;    max-width: fit-content;
    margin: 0 0 0 auto;">
        1 Selected
      </v-chip>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group v-model="filter_type">
        <v-radio label="All" value=""></v-radio>
        <v-radio label="Paid" value="paid"></v-radio>
        <v-radio label="Pilot" value="pilot"></v-radio>
      </v-radio-group>
    </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels bg-color="white" flat style="border-bottom:1px solid #0003;border-radius:0px !important;margin-left: 1px;">
      <v-expansion-panel  style="padding-top:10px;padding-bottom:10px;">
        <v-expansion-panel-header>
          Company Status
          <v-chip 
        v-if="filter_status" 
        style="float: right; background-color: white;border:1px solid #0ab39c;color:#0ab39c;    max-width: fit-content;
    margin: 0 0 0 auto;">
        1 Selected
      </v-chip>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group v-model="filter_status">
        <v-radio label="All" value=""></v-radio>
        <v-radio label="Active" value="true"></v-radio>
        <v-radio label="Inactive" value="false"></v-radio>
      </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn @click="applyFilters()" style="    background-color: #0ab39c;
    color: white;box-shadow: none;    position: absolute; text-transform: capitalize;
    bottom: 12px;
    right: 12px;">
    Apply filter
  </v-btn>
  </v-list>
    </v-navigation-drawer>
  <addCompany ref="addcompany" class="" />
  <v-dialog
      v-model="logDetails"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Audit details
        </v-card-title>
        <v-card-text style="max-height: 500px; overflow-y:auto;">
          <v-row v-if="logData">
              <v-col cols="3">
                <strong>Date : </strong>
              </v-col>
              <v-col cols="9">
                <p class="mb-0">{{moment(logData.created_at).format('DD-MM-YYYY  HH:mm')}}</p>
              </v-col>
              <v-col cols="3">
                <strong>IP Address : </strong>
              </v-col>
              <v-col cols="9">
                <p class="mb-0" v-if="logData.extra_data && logData.extra_data.client_ip">{{logData.extra_data.client_ip}}</p>
              </v-col>
              <v-col cols="3">
                <strong>User : </strong>
              </v-col>
              <v-col cols="9">
                <strong  v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</strong><br>
                <p class="mb-0" v-if="logData.created_by && logData.created_by.email">{{logData.created_by.email}}</p>
              </v-col>
              <v-col cols="3">
                <strong>Activity : </strong>
              </v-col>
              <v-col cols="9" v-if="logData && logData.type">
                <p class="mb-0" v-if="logData && logData.type">{{logData.type.split('_').join(' ')}}</p>
                <p v-if="logData.type === 'user_login_failed'" class="mb-0"><span v-if="logData.username">{{logData.username}}</span>'s login failed.</p>
                <p v-if="logData.type === 'user_login_failed'" class="mb-0">Error: {{logData.error}}</p>
                <p v-if="logData.type === 'user_logged_in'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has logged in successfully.</p>
                <p v-if="logData.type === 'user_logged_out'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has logged out successfully.</p>
                <p v-if="logData.type === 'report_download'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has downloaded the happiness index report of <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'admin_added'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has added admin in <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'plan_settings'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has changed plan details for <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'sub_driver_updated'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has updated sub driver <strong v-if="logData && logData.data">{{logData.data}}</strong> for <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'sub_driver_added'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has added a new sub driver <strong v-if="logData && logData.data">{{logData.data}}</strong> for <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'sub_driver_deleted'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has deleted a sub driver <strong v-if="logData && logData.data">{{logData.data}}</strong> for <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'company_settings'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has changed company settings for <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'company_profile'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has changed company profile for <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'company_added'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has added a new company <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'reminders_trigger'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has trigger reminders for <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'reminders_trigger'" class="mb-0">
                  <span v-if="logData.data && logData.data.inProgress">Inprgress: {{logData.data.inProgress}}</span>
                  <span v-else>Inprgress: 0</span>
                  <br>
                  <span v-if="logData.data && logData.data.initiated">Initiated: {{logData.data.initiated}}</span>
                  <span v-else>Initiated: 0</span>
                </p>
                <p v-if="logData.type === 'touchpoint_upload'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has uploaded the touchpoint for <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'touchpoint_deleted'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has deleted a touchpoint <strong v-if="logData && logData.data">{{logData.data}}</strong> for <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
                <p v-if="logData.type === 'touchpoint_updated'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has updated a touchpoint <strong v-if="logData && logData.data">{{logData.data}}</strong> for <strong><span style="cursor:pointer;" @click="openCompany(logData.company.id)" v-if="logData.company && logData.company.company_name">{{logData.company.company_name}}</span></strong>.</p>
              </v-col>
              <v-col v-if="logData.type === 'touchpoint_upload' && logData.data" cols="3">
                <strong>Results :  </strong>
              </v-col>
              <v-col v-if="logData.type === 'touchpoint_upload' && logData.data" cols="9">
                Success : {{logData.data.success}}<br>
                Failure : {{logData.data.failure}}<br>
                Download file : <a @click="downloadTouchpointSheet(logData.data.file_url)">Download</a>
              </v-col>
              <v-col cols="3" v-if="logData.old_data">
                <strong>Old data : </strong>
              </v-col>
              <v-col cols="9" v-if="logData.old_data">
                <span v-for="(old, i) in logData.old_data" :key="i">
                  <span v-if="i !== 'activatedAt' && i !== 'company_avatar_id'" >
                    <span style="">
                      {{i.split('_').join(' ')}}
                    </span>: {{old}} <br>
                  </span>
                  <span v-else-if="i === 'activatedAt'"> Go live date : {{moment(old).format('DD-MM-YYYY  HH:mm')}}</span><br v-else-if="i === 'activatedAt'">
                  <span v-else-if="i === 'company_avatar_id'">
                    Old company avatar : <br>
                    <img style="width:13%;" :src="getAvatarAudit(old)" alt="image"><br>
                  </span>
                </span>
              </v-col>
              <v-col cols="3" v-if="logData.new_data">
                <strong>New data : </strong>
              </v-col>
              <v-col cols="9" v-if="logData.new_data">
                <span v-for="(newData, j) in logData.new_data" :key="j">
                  <span v-if="j !== 'activatedAt' && j !== 'company_avatar_id'">
                    {{j.split('_').join(' ')}}: {{newData}} <br>
                  </span>
                  <span v-else-if="j === 'activatedAt'"> Go live date : {{moment(newData).format('DD-MM-YYYY  HH:mm')}}</span>
                  <br v-if="j === 'activatedAt'">
                  <span v-else-if="j === 'company_avatar_id'">
                    New company avatar : <br>
                    <img style="width:13%;" :src="getAvatarAudit(newData)" alt="image"><br>
                  </span>
                </span>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
            @click="logDetails = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from 'axios';
import moment from 'moment';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue from 'vue';
import InfiniteLoading from 'vue-infinite-scroll';
import VueSweetalert2 from 'vue-sweetalert2';
import { mapState } from 'vuex';
import addCompany from './dialogs/AddCompany';
var count = 0;

  Vue.use(VueSweetalert2);
  am4core.useTheme(am4themes_animated);
  export default {
    name: 'Dashboard',

    components: {
      axios,
      InfiniteLoading,
      addCompany
    },

    data: () => ({
      filterOptions: ['Paid', 'Pilot'],
      filtersMenu1: false,
      filtersMenu: false,
      drawerOpen: false,
      moment: moment,
      totalCount: '',
      pushToFirst: [],
      pushToLast: [],
      logDetails: false,
      logData: [],
      auditLog: [],
      data: [],
      imageData: '',
      imageData1: '',
      imageData2: '',
      imageData3: '',
      imageData4: '',
      imageData5: '',
      companyLogo: '',
      avatarList: [],
      pagination: {
        rowsPerPage: 20,
        page: 1,
        length: 1
      },
      busy: false,
      searchCompany: '',
      filter_status: null,
      filter_type: null,
      dashboardData: null,
    }),
    watch : {
      searchCompany () {
        this.getCompanyDetails()
      }
    },
  computed: {
    badgeCount() {
    return (this.filter_type && this.filter_status) ? 2 : (this.filter_type || this.filter_status) ? 1 : null;
  },
    drawerOpen() {
      return this.$store.getters.drawerOpen;
    },
    filter_type() {
    return this.$route.query.filter_type || this.filter_type;
  },
  filter_status() {
    return this.$route.query.filter_status || this.filter_status;
  },
    ...mapState({
      drawerOpen: state => state.drawerOpen, 
      snackbar: state => state.snackbar,
      user: state => state.user
    }),
    getPreNumber () {
      const num = (this.pagination.page - 1) * 20 + 1;
      if (num > this.totalCount) {
        return this.totalCount;
      }
      return num;
    },
    getNextNumber () {
      const num = (this.pagination.page - 1) * 20 + 20;
      if (num > this.totalCount) {
        return this.totalCount;
      }
      return num;
    }
  },
    methods: {
    applyFilters() {
      this.drawerOpen = !this.drawerOpen;
      this.getCompanyDetails();
    },
      toggleDrawer() {
        this.drawerOpen = !this.drawerOpen;
      this.$store.dispatch('toggleDrawer');
    },
    
      resetFilters () {
        this.drawerOpen = !this.drawerOpen;
        this.filter_status=null;
      this.filter_type=null;
      this.getCompanyDetails();
    },
      downloadTouchpointSheet (link) {
        setTimeout(() => {
          window.open(link);
        }, 100);
      },
      getAvatarAudit (a) {
        let avatar = '';
        this.$lodash.each(this.avatarList, (val, i) => {
          if (val.id == a) {
            avatar = val.avatar;
          }
        });
        return avatar;
      },
      openCompany (id) {
        this.$router.push(`/company-details?cid=${id}`);
      },
      viewLogDetails (data) {
        this.logDetails = true;
        this.logData = data;
      },
      addNewAvatar () {
        this.avatarList.push({
          avatar: ''
        })
      },
      getIcon (type) {
        let icon = 'mdi-office-building-outline';
        if (type === 'user_logged_in') {
          icon = 'mdi-account-arrow-left-outline'
        }
        if (type === 'user_login_failed') {
          icon = 'mdi-account-remove-outline'
        }
        if (type === 'reminder') {
          icon = 'mdi-bell-ring-outline'
        }
        if (type === 'company_added') {
          icon = 'mdi-office-building-outline'
        }
        if (type === 'sub_driver_deleted' || type === 'sub_driver_updated' || type === 'sub_driver_added') {
          icon = 'mdi-lifebuoy'
        }
        if (type === 'company_settings') {
          icon = 'mdi-office-building-outline'
        }
        if (type === 'company_profile') {
          icon = 'mdi-office-building-outline'
        }
        if (type === 'plan_settings') {
          icon = 'mdi-office-building-outline'
        }
        if (type === 'user_logged_out') {
          icon = 'mdi-account-arrow-right-outline'
        }
        if (type === 'chat_feedback') {
          icon = 'mdi-download-outline'
        }
        if (type === 'report_download') {
          icon = 'mdi-file-download-outline'
        }
        if (type === 'touchpoint_upload') {
          icon = 'mdi-map-marker-path'
        }
        if (type === 'admin_added') {
          icon = 'mdi-account-plus-outline'
        }
        return icon;
      },
      getColor (type) {
        let color = 'indigo lighten-4';
        if (type === 'user_logged_in') {
          color = 'green lighten-4'
        }
        if (type === 'user_login_failed') {
          color = 'red lighten-4'
        }
        if (type === 'user_logged_out') {
          color = 'green lighten-4'
        }
        if (type === 'reminder') {
          color = 'indigo lighten-4'
        }
        if (type === 'sub_driver_deleted' || type === 'sub_driver_updated' || type === 'sub_driver_added') {
          color = 'indigo lighten-4'
        }
        if (type === 'company_added') {
          color = 'indigo lighten-4'
        }
        if (type === 'plan_settings') {
          color = 'indigo lighten-4'
        }
        if (type === 'company_settings') {
          color = 'indigo lighten-4'
        }
        if (type === 'company_profile') {
          color = 'indigo lighten-4'
        }
        if (type === 'chat_feedback') {
          color = 'indigo lighten-4'
        }
        if (type === 'touchpoint_upload') {
          color = 'indigo lighten-4'
        }
        if (type === 'report_download') {
          color = 'indigo lighten-4'
        }
        if (type === 'admin_added') {
          color = 'indigo lighten-4'
        }
        return color;
      },
      removeImage () {
        this.imageData = '';
      },
      removeImage1 () {
        this.imageData1 = '';
      },
      removeImage2 () {
        this.imageData2 = '';
      },
      removeImage3 () {
        this.imageData3 = '';
      },
      removeImage4 () {
        this.imageData4 = '';
      },
      removeImage5 () {
        this.imageData5 = '';
      },
      previewImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = (e) => {
              if (input.files[0].size < 2000000) {
                this.imageData = e.target.result;
                this.companyLogo = input.files[0];
                this.addLogo();
              } else {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: 'Image size cannot be more then 2MB!'
                });
              }
          }
          reader.readAsDataURL(input.files[0]);
      }
      
  },
      addLogo () {
        const formData = new FormData();
        if (this.companyLogo) {
          formData.append('avatar', this.companyLogo);
        }
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.post(`${process.env.VUE_APP_API_URL}company/avatar/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
        }).then((response) => {
          if (response && response.data) {
            this.imageData = '';
            this.imageData1 = '';
            this.imageData2 = '';
            this.imageData3 = '';
            this.imageData4 = '';
            this.imageData5 = '';
            this.getAvatar();
            this.$store.dispatch('updateSnackbar', {
              color: 'success',
              show: true,
              text: 'Company updated successfully!'
            });
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
      displayPre () {
        if (this.pagination.page > 1) {
          this.pagination.page = this.pagination.page - 1;
          this.infiniteHandler()
        }
      },
      displayNext () {
        if (this.pagination.page < (this.totalCount / 20)) {
          this.pagination.page = this.pagination.page + 1;
          this.infiniteHandler()
        }
      },
      infiniteHandler ($state) {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.get(`${process.env.VUE_APP_API_URL}company/audit`, {
          params: {
            page_offset: this.pagination.page,
            page_limit: this.pagination.rowsPerPage,
          }
        }).then((response) => {
          if (response.data && response.data.count) {
            this.totalCount = response.data.count;
          }
          if (response && response.data && response.data.results) {
            this.auditLog = response.data.results;
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch audit logs, Please try again later!'
            });
          }
        });
      },
      disableImage (id) {
        this.$swal({
          title: 'Are you sure?',
          text: "You want to disable this avatar!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, disable!'
        }).then((result) => {
          if (result.isConfirmed) {
            axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
            axios.patch(`${process.env.VUE_APP_API_URL}company/avatar/?avatar_id=${id}`, {
              is_active: false,
            }).then((response) => {
              if (response && response.data) {
                this.avatarList = [];
                this.getAvatar();
                this.$store.dispatch('updateSnackbar', {
                  color: 'success',
                  show: true,
                  text: 'Company updated successfully!'
                });
              }
            }).catch((err) => {
              if (err.response.status === 429) {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: err.response.data.error
                });
              } else {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: 'Unable to fetch details, Please try again later!'
                });
              }
            });
          }
        });
      },
      enableImage (id) {
        this.$swal({
          title: 'Are you sure?',
          text: "You want to enable this avatar!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, enable!'
        }).then((result) => {
          if (result.isConfirmed) {
            axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
            axios.patch(`${process.env.VUE_APP_API_URL}company/avatar/?avatar_id=${id}`, {
              is_active: true,
            }).then((response) => {
              if (response && response.data) {
                this.avatarList = [];
                this.getAvatar();
                this.$store.dispatch('updateSnackbar', {
                  color: 'success',
                  show: true,
                  text: 'Company updated successfully!'
                });
              }
            }).catch((err) => {
              if (err.response.status === 429) {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: err.response.data.error
                });
              } else {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: 'Unable to fetch details, Please try again later!'
                });
              }
            });
          }
        });
      },
      pushInactiveToLast () {
        this.pushToFirst = [];
        this.pushToLast = [];
        for (let i = 0; i < this.avatarList.length; i++) {
          if (this.avatarList[i].is_active === true) {
            this.pushToFirst.push(this.avatarList[i]);
          }
        }
        for (let i = 0; i < this.avatarList.length; i++) {
          if (this.avatarList[i].is_active === false) {
            this.pushToLast.push(this.avatarList[i]);
          }
        }
      },
      getAvatar () {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.get(`${process.env.VUE_APP_API_URL}company/avatar`).then((response) => {
          if (response && response.data) {
            this.avatarList = response.data;
            this.pushInactiveToLast();
            setTimeout(() => {
              this.avatarList = this.pushToFirst.concat(this.pushToLast);
            }, 200);
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
      logout () {
      axios.post(`${process.env.VUE_APP_API_URL}logout`).then((response) => {
        if (response && response.data.status === 200) {
          this.$store.dispatch('deleteSession', 'logout');
          this.$router.push('/login');
        } else {
          this.$notify({
            group: 'foo',
            title: 'Error while logging out!',
            type: 'warn'
          });
          this.$store.dispatch('deleteSession', 'logout');
          this.$router.push('/login');
        }
      }, () => {
        this.$store.dispatch('deleteSession', 'logout');
        this.$router.push('/login');
      });
    },
    changeTab(url) {
    let baseUrl = `/${url}`;
    if (url === 'company-list') {
      baseUrl += `?page=1`;
      if (this.filter_type) {
        baseUrl += `&filter_type=${this.filter_type}`;
      }
      if (this.filter_status) {
        baseUrl += `&filter_status=${this.filter_status}`;
      }
    } else {
      baseUrl = `/${url}`;
    }
    this.$router.push(baseUrl);
  },
      scrollToElement() {
    const el = this.$refs.scrollToMe;

    if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      el.scrollIntoView({behavior: 'smooth'});
    }
  },
      openDetails (item) {
        this.$router.push(`/company-details?cid=${item.id}`);
      },
      applyFilter (data) {
        this.filter_type = data;
        this.getCompanyDetails();
        this.scrollToElement();
      },
      openModal () {
        this.$refs.addcompany.dialog = true;
        this.$refs.addcompany.company = {};
        this.$refs.addcompany.company.company_avatar = '';
        this.$refs.addcompany.company.company_avatar_id = '';
        this.$refs.addcompany.company.companies_avatar = this.avatarList;
        this.$refs.addcompany.company.is_active = true;
        this.$refs.addcompany.company.chat_cobranding_enabled = false;
        this.$refs.addcompany.company.allow_stage_edit = false;
      },
      loadMore() {
      this.busy = true;
 
        setTimeout(() => {
          for (var i = 0, j = 10; i < j; i++) {
            this.data.push({ name: count++ });
          }
          this.busy = false;
        }, 1000);
      },
      editCompany (data) {
        this.$router.push(`/company-details?cid=${data.id}&editview=true`);
      },
      lineChart (val) {
        am4core.useTheme(am4themes_animated);
        let chart = am4core.create("chartdiv1", am4charts.XYChart);
        const temp = [];
          for (const [key, value] of Object.entries(val)) {
          const t = {
            date: key,
            value: value.account_type.paid ? value.account_type.paid : 0,
            value2: value.account_type.pilot ? value.account_type.pilot : 0
          };
          temp.push(t);
        }
        chart.data = temp;
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.skipEmptyPeriods = true;
        dateAxis.baseInterval = {
          "timeUnit": "month",
          "count": 1
        };
        dateAxis.dateFormatter.dateFormat = "yyyy-MM";
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        function createSeries(field, name) {
          let series = chart.series.push(new am4charts.LineSeries());
          series.dataFields.valueY = field;
          series.dataFields.dateX = "date";
          series.name = name;
          series.tooltipText = "{name}: [b]{valueY}[/]";
          series.strokeWidth = 2;
          let bullet = series.bullets.push(new am4charts.CircleBullet());
          bullet.circle.stroke = am4core.color("#fff");
          bullet.circle.strokeWidth = 2;
          return series;
        }

        let series1 = createSeries("value", "Paid");
        let series2 = createSeries("value2", "Pilot");

        chart.legend = new am4charts.Legend();
        chart.cursor = new am4charts.XYCursor();
      },
accountTypeChart (data) {
  const that = this;
  am4core.addLicense("ch-custom-attribution");
  var chart = am4core.create("chartdiv", am4charts.PieChart);

// Add data
chart.data = [{
  "country": "Initiated",
  "litres": data.stats[3].initiated
}, {
  "country": "InProgress",
  "litres": data.stats[1].inProgress
}, {
  "country": "Completed",
  "litres": data.stats[2].completed
}]

// Add and configure Series
var pieSeries = chart.series.push(new am4charts.PieSeries());
pieSeries.dataFields.value = "litres";
pieSeries.dataFields.category = "country";


// Let's cut a hole in our Pie chart the size of 40% the radius
chart.innerRadius = am4core.percent(70);

// Disable ticks and labels

pieSeries.labels.template.disabled = true;
pieSeries.ticks.template.disabled = true;

// Disable tooltips
pieSeries.slices.template.tooltipText = "{category}: {value.value}";
// pieSeries.slices.template.tooltipText = "";
pieSeries.slices.template.propertyFields.fill = "color";
pieSeries.labels.template.disabled = true;


pieSeries.ticks.template.disabled = true;
pieSeries.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
var label = pieSeries.createChild(am4core.Label);
console.log(chart.data);
label.text = 'Chat this month';
label.horizontalCenter = "middle";
label.verticalCenter = "middle";
label.fontSize = 15;
label.fontWeight = 300;
label.x = 0;
label.y = -10;
var label1 = pieSeries.createChild(am4core.Label);
console.log(chart.data);
label1.text = data.this_month;
label1.horizontalCenter = "middle";
label1.verticalCenter = "middle";
label1.fontSize = 30;
label1.x = 0;
label1.y = 15;
      },
      getCompanyDetails () {
        console.log('Query parameters:', this.$route.query);

        const queryParams = {
          count: 'true',
          page_limit: this.pagination.rowsPerPage,
          page_offset: this.pagination.page || 1,
          raw_search_string: this.searchCompany,
          active_plan__account_type: this.filter_type ? this.filter_type : undefined,
      is_active: this.filter_status  ? this.filter_status : undefined,
        };
        axios.defaults.headers.common['Authorization'] = `JWT ${this.user.access_token}`;
        axios.get(`${process.env.VUE_APP_API_URL}company/dashboard?fields=dashboard_overview,companies_response_trends,chat_response_stats,chat_response_stats`, {
          params: queryParams
        }).then((response) => {
          if (response && response.data) {
            this.dashboardData = response.data;
            this.pagination.length = Math.ceil(response.data.companies_overview.companies_count / this.pagination.rowsPerPage);
            // console.log(this.company);
            if (response.data && response.data.dashboard_overview.chat_response_stats.this_month) {
              this.accountTypeChart(response.data.dashboard_overview.chat_response_stats);
            }
            if (response.data && response.data.dashboard_overview.companies_response_trends) {
              this.lineChart(response.data.dashboard_overview.companies_response_trends);
            }
            
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.config.savingStage = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          }
        });
      },
      reloadPage () {
        // window.location.reload();
      },
    },
    mounted () {
  this.filter_type = this.$route.query.filter_type || null;
  this.filter_status = this.$route.query.filter_status || null;
      this.getCompanyDetails();
      this.loadMore();
      this.getAvatar();
      this.infiniteHandler();
      if (!this.user.email) {
        this.$router.push(`/login`);
      }
    }
  }
</script>
<style lang="scss" >
.filterDrawer .v-icon.v-icon {
  font-size: 14px !important;
}
.filterDrawer .v-input--selection-controls {
  margin-top: 0px !important;
}
.image-preview:hover .overlay1 {
  display: block;
}
.image-preview:hover .overlay2 {
  display: block;
}
.overlay1 {
  display: none;
  position: absolute; 
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  width: 100%;
  cursor:pointer;
  transition: .5s ease;
  opacity:1;
  font-size:16px;
  color: white;
  padding: 10px 0;
  text-align: center;
}
.overlay2 {
  display: none;
  position: absolute; 
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  width: 100%;
  cursor:pointer;
  transition: .5s ease;
  opacity:1;
  font-size:16px;
  color: white;
  padding: 10px 15px 15px 15px;
  text-align: center;
}
input[type=file] {
  cursor: pointer;
  width: 130px;
  height: 130px;
  padding:10px;
  border-radius: 10px;
  overflow: hidden;
  background: #f1f1f1;
}

input[type=file]:before {
  content: 'Drag and drop \A or \A click to add \A avatar';
  vertical-align: middle;
  white-space: pre-wrap;
  text-align: center;
  font-size: .8em;
  display: inline-block;
  background: #f1f1f1;
  padding: 20% 10px;
  font-family: Helvetica, Arial, sans-serif;
}

input[type=file]::-webkit-file-upload-button {
  visibility: hidden;
}
.heading {
  font-size: 16px;
  padding-top:10px;
}
.dashboardCount {
  font-size: 70px;
  padding:10%;
}
.mainBox {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 16px 11px rgb(0 0 0 / 6%), 0 4px 8px rgb(0 0 0 / 10%) !important;
  height: 16rem;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  text-align: center;
}
.tableDash {
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 4px 16px 11px rgb(0 0 0 / 6%), 0 4px 8px rgb(0 0 0 / 10%) !important; 
  margin-top:15px;
}
.v-btn--active {
  background-color: #050550 !important;
}
.disabledView {
  .v-btn--active {
    background-color: #858585 !important;
  }
}
#chartdiv1 {
  height: 360px;
  width:100%;;
}

.primary--text {
  color: #050550 !important;
}
#chartdiv {
  width: 100%;
  height: 210px;
  
}
.headingprofile {
  text-align: left;
  h2 {
    color: #263238;
    font-weight: 500;
    line-height: 1.1;
    font-size: 20px;
    margin: 0 0 6px;
    width: 100%;
  }
}
.firstNew::before {
    background: #fff none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.overlay {
  position: absolute; 
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.3); /* Black see-through */
  width: 100%;
  transition: .5s ease;
  opacity:1;
  font-size:16px;
  color: white;
  padding: 72% 0 20% 0;
  text-align: center;
}
::-webkit-scrollbar-track
{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #ffffff;
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: #ffffff;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #ccc;
}
.v-timeline-item__inner-dot {
  .v-icon {
    color: #263238;
  }
}
.filterButton {
  text-transform: capitalize;
  border-radius:6px;
  box-shadow: none !important;
    color: #0ab39c !important;
    background: #daf4f0 !important;
    border-color: transparent;
}
.filterButton:hover {
  box-shadow: none !important;
  color: white !important;
  background: #0ab39c !important;
  border-color: transparent !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none !important;
}
.v-btn-toggle--group > .v-btn.v-btn {
  margin-left: 0px !important;
  margin-right:0px !important;
  border-color: #d7d7d7 !important;
}
.v-btn-toggle > .v-btn.v-btn--active {
  background-color: #0ab39c !important;
  color: white !important;

}
.hover:hover {
  background-color: #0ab39c;
    color: white;
    position: relative;
    padding: 10px;
    display:flex;
}
.hover:hover::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 12px;
  height: 12px;
  background-color: #0ab39c;
}
.toggleButton {
    text-transform: capitalize; 
}
.responsive-avatar {
    width: 100%;
    height: 100%;
    max-width: 140px;
    max-height: 140px;
    min-width: 80px;
    min-height: 80px;
}

.responsive-avatar img {
    width: 100%;
    height: auto;
    border-radius: 50%;
}
</style>
